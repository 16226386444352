<template>
<div class="doc-container">
    <!-- Content -->
    <CCard class="p-0">
        <!-- Header -->
        <CCardHeader>
            <div class="d-flex">
                <div class="flex-grow-1">
                    <h5 class="mb-0" style="line-height:2rem">
                        Revisi
                    </h5>
                </div>
                <div class="flex-grow-0 ml-10"></div>
            </div>
        </CCardHeader>
        <!-- Table -->
        <CCardBody class="doc-action-container">
            <v-data-table
                :loading="loading"
                loading-text="Memuat Data..."
                :headers="modelTable.headers"
                :options.sync="modelOptionsTable"
                :server-items-length="modelTable.totalData"
                :multi-sort="false"
                :items="modelTable.dataList"
                :footer-props="modelTable.footerProps"
                class="elevation-1">
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2 text-warning" @click="goToDetail(item)">
                        mdi-eye
                    </v-icon>
                    <v-icon small class="mr-2 text-primary" @click="showRevisionModal(item)">
                        mdi-pencil
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <span>Tidak ada revisi surat ditemukan.</span>
                </template>
            </v-data-table>
        </CCardBody>
    </CCard>
    
	<!-- Revison Popup Modal & Input Form -->
	<revision-form
        v-bind:popup-modal-prop="modelForm.popupModal"
        v-bind:edited-index-prop="modelForm.editedIndex"
        v-bind:model-prop="modelForm.input"
        v-on:close-popup-modal="closeRevisionModal">
    </revision-form>
</div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import RevisionForm from '@/components/document/RevisionForm.vue'
export default {
	names: 'Approval',
	data () {
        const modelTable = {
			headers: [
				{ text: 'Perihal', value: 'title', align: 'left', sortable: true },
				{ text: 'Nomor Surat', value: 'doc_no', align: 'left', sortable: true },
                { text: 'Revisi Ke', value: 'version', align: 'center', sortable: false },
                { text: 'Catatan', value: 'comments[0].message', align: 'left', sortable: false },
                { text: 'Diajukan Oleh', value: 'comments[0].user.fullname', align: 'left', sortable: true },
                { text: 'Diajukan Pada', value: 'created_at', align: 'center', sortable: false },
				{ text: 'Aksi', value: 'actions', align: 'center', sortable: false }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': 'Data Ditampilkan'
			},
			totalData: 0,
			dataList: []
        }
        const modelForm = {
			editedIndex: -1,
			popupModal: {
				isShow: false
			},
			input: {
                revisionId: null,
                docNo: null,
                title: null,
			},
			defaultValue: {
                revisionId: null,
                docNo: null,
                title: null,
			}
        }

		return {
			loading: true,
            modelTable,
            modelOptionsTable: {},
            modelForm
		}
    },
    watch: {
		modelOptionsTable: {
			deep: true,
			handler () {
				this.getDataList()
					.then( data => {
						this.modelTable.dataList = data.items
						this.modelTable.totalData = data.total
					})
			}
        },
        modelForm: {
            handler: function (newValue, oldValue) {
                this
            },
            deep: true,
            imediate: true
        }
    },
	mounted () {
        this.getDataList()
            .then( data => {
                this.modelTable.dataList = data.items
                this.modelTable.totalData = data.total
            })
	},
	methods: {
        // APIs
        getDataList () {
            this.loading = true
			return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.modelOptionsTable
                
				this.$store.dispatch(
					{
                        type: 'revision/get',
						pagination: {
							limit: itemsPerPage,
							offset: (page-1) * itemsPerPage
						}
					}
				).then( res => {
					let items = res.result
					let total = res.total_count
					resolve({ items, total })
				}).catch( err => {
                    reject(err)
                })
			})
        },
        // Navigation & Actions
        goToDetail (modelProp) {
            let docId = modelProp.doc_id
			this.$router.push('/document_detail/' + docId)
        },
        showRevisionModal (modelProp) {
            this.modelForm.input = {
                revisionId: modelProp.id_revision,
                docNo: modelProp.doc_no,
                title: modelProp.title
			}
            this.modelForm.popupModal.isShow = true
		},
		closeRevisionModal (refreshState) {
			this.modelForm.popupModal.isShow = false
            this.modelForm.input = Object.assign({}, this.modelForm.default)
            if (refreshState === true) {
                this.getDataList()
                    .then( data => {
                        this.modelTable.dataList = data.items
                        this.modelTable.totalData = data.total
                    })
            }
        }
    },
	components: {
        'revision-form': RevisionForm
    }
}
</script>

<style scoped></style>