<template>
    <!-- Revision Modal -->
    <CModal :show.sync="popupModalProp.isShow">
        <template #header>
            <h5 class="modal-title">Revisi Surat</h5>
        </template>
        <!-- Input Form -->
        <div class="p-1">
            <CInput
                v-model="revisionForm.input.revisionId"
                type="hidden"/>
            <CInput
                v-model="revisionForm.input.title"
                label="Perihal"
                placeholder="Perihal"
                :is-valid="revisionForm.validate.title"
                @change="validateForm('title')" />
            <CInput
                v-model="revisionForm.input.docNo"
                label="Nomor Surat"
                placeholder="Nomor Surat"
                :is-valid="revisionForm.validate.docNo"
                @change="validateForm('docNo')" />
            <!-- Attachment -->
            <div class="title">:: Surat<hr class="mt-0"/></div>
            <div class="mb-20">
                <label class="typo__label">Sumber Surat</label>
                <multiselect
                    v-model="attachmentForm.input.source"
                    :options="attachmentForm.sourceOptions"
                    :multiple="false"
                    :allow-empty="false"
                    :close-on-select="true"
                    placeholder="Pilih Sumber Surat"
                    @input="validateForm('attachment')">
                </multiselect>
            </div>
            <!-- Upload File -->
            <div v-if="attachmentForm.input.source == 'Unggah Surat'" class="mb-20">
                <div role="group" class="form-group">
                    <label class="">Pilih Surat</label>
                    <CInputFile
                        ref="file"
                        accept=".doc,.docx,.pdf"
                        :placeholder="attachmentForm.placeholder"
                        custom
                        @change="selectFile"/>
                    <CInput
                        v-model="attachmentForm.input.filename"
                        type="hidden" />
                </div>
            </div>
            <!-- Choose Template -->
            <div v-else class="p-0 m-0">
                <div class="mb-20">
                    <label class="typo__label">Pilih Template</label>
                    <multiselect
                        v-model="attachmentForm.input.selectedTemplate"
                        :options="attachmentForm.templateOptions"
                        :multiple="false"
                        :allow-empty="false"
                        :close-on-select="true"
                        label="template_name"
                        track-by="template_id"
                        placeholder="Pilih Template"
                        @input="selectTemplate">
                    </multiselect>
                </div>
                <div class="mb-20">
                    <CTextarea
                        v-for="(item, index) in attachmentForm.input.dynamicField"
                        :key="index"
                        v-model="item.model"
                        :label="item.label"
                        :placeholder="item.placeholder"
                        rows="3" />
                </div>
            </div>
        </div>
        <template #footer>
            <CButton @click="closePopupModal(false)" class="btn-sm">Batal</CButton>
            <CButton @click="saveRevision" color="success" class="btn-sm" :disabled="revisionForm.submitBtn.disabled">
                <font-awesome-icon v-if="revisionForm.submitBtn.loader" icon="spinner" spin/> {{ revisionForm.submitBtn.text }}
            </CButton>
        </template>
    </CModal>
</template>

<script>
export default {
    props: [
        'popupModalProp',
        'editedIndexProp',
        'modelProp'
    ],
    data () {
        return {
            saveState: {
                attachment: false,
                revision: false
            },
            attachmentForm: {
                sourceOptions: ['Unggah Surat', 'Template'],
                templateOptions: [],
                input: {
                    source: 'Unggah Surat',
                    file: null,
                    filename: null,
                    templateId: null,
                    selectedTemplate: null,
                    dynamicField: []
                },
                default: {
                    source: 'Unggah Surat',
                    file: null,
                    filename: null,
                    templateId: null,
                    selectedTemplate: null,
                    dynamicField: []
                },
                placeholder: 'Pilih Surat (DOC,DOCX,PDF)'
            },
            revisionForm: {
                editedIndex: this.editedIndexProp,
                input: {
                    revisionId: this.modelProp.revisionId,
                    isPublishRollback: false,
                    type: 'revision',
                    docNo: this.modelProp.docNo,
                    title: this.modelProp.title,
                    docFileId: null
                },
                defaultValue: {
                    revisionId: null,
                    isPublishRollback: false,
                    type: 'revision',
                    docNo: null,
                    title: null,
                    docFileId: null
                },
                validate: {
                    docNo: null,
                    title: null,
                    attachment: null
                },
                submitBtn: {
                    loader: false,
                    text: 'Simpan',
                    disabled: true
                }
            }
        }
    },
    watch: {
        modelProp: {
            handler: function (newValue, oldValue) {
                this.revisionForm.input = {
                    revisionId: newValue.revisionId,
                    isPublishRollback: false,
                    type: 'revision',
                    docNo: newValue.docNo,
                    title: newValue.title,
                    docFileId: null
                }
                this.validateForm('docNo')
                this.validateForm('title')
            },
            deep: true,
            imediate: true
        }
    },
    mounted () {
        this.getTemplateOptions()
    },
    methods: {
        // Option
        getTemplateOptions () {
            this.$store.dispatch({
				type: 'document/getTemplate'
			}).then( res => {
				this.attachmentForm.templateOptions = res.result
			}).catch( err => {
				this.$toasted.error('Gagal mengambil data template').goAway(3000)
            })
        },
        selectTemplate (selectedTemplate) {
            let templateId = selectedTemplate.template_id
            this.$store.dispatch({
				type: 'document/fetchTemplate',
				filter: {
                    templateId: templateId,
                    outputType: 'details'
                }
			}).then( res => {
                let dynamicFields = []
                let fields = res.template_field
                fields.forEach((obj) => {
                    let dynamicInput = {
                        label: obj,
                        placeholder: 'Masukkan '+ obj,
                        model: ''
                    }
                    dynamicFields.push (dynamicInput)
                })
                this.attachmentForm.input.dynamicField = dynamicFields
			}).catch( err => {
				this.$toasted.error('Gagal memuat data, silahkan coba kembali!').goAway(3000)
			})
            this.validateForm('attachment')
        },
        // Attachment
        selectFile (event) {
            if (event.length > 0) {
                let fileCollection = []
				event.forEach((item) => {
                    fileCollection.push(item.name)
                })
                // Inject attachmentForm.input.file
                this.attachmentForm.placeholder = fileCollection.join()
                this.attachmentForm.input.file = event
                // Inject attachmentForm.input.filename
                let filename = fileCollection.join().replace(' ','_')
				this.attachmentForm.input.filename = filename
            } else {
                this.attachmentForm.placeholder = 'Belum memilih surat'
            }
            this.validateForm('attachment')
		},
        uploadDocument () {
            return new Promise ( (resolve, reject) => {
                this.$store.dispatch({
                    type: 'document/uploadDocument',
                    formInput: {
                        documentFile: this.attachmentForm.input.file,
                        fileName: this.attachmentForm.input.filename
                    }
                }).then( res => {
                    resolve(res.result)
                }).catch( err => {
                    reject(err)
                })
            })
        },
        uploadTemplate () {
            let templateId = this.attachmentForm.input.selectedTemplate.template_id
            this.attachmentForm.input.templateId = templateId
            let filename = this.revisionForm.input.title
            this.attachmentForm.input.filename = filename

            return new Promise ( (resolve, reject) => {
                this.$store.dispatch({
                    type: 'document/createMyDocument',
                    formInput: this.attachmentForm.input
                }).then( res => {
                    resolve(res.result)
                }).catch( err => {
                    reject(err)
                })
            })
        },
        // Revision
        updateRevision () {
            return new Promise ( (resolve, reject) => {
				this.$store.dispatch({
					type: 'revision/update',
					formInput: this.revisionForm.input
				}).then( res => {
					resolve(res.result)
				}).catch( err => {
					reject(err)
				})
			})
        },
        async saveRevision () {
            // Begin the save process
			this.revisionForm.submitBtn = {
                loader: true,
                text: 'Memproses...'
            }
            // Upload attachment
			if (!this.saveState.attachment) {
				try {
                    let attachmentProp = null
                    attachmentProp = (this.attachmentForm.input.source == 'Unggah Surat') ?
                        await this.uploadDocument() : await this.uploadTemplate()
                    this.saveState.attachment = true
					this.revisionForm.input.docFileId = attachmentProp.doc_file_id
				} catch (err) {
					this.saveState.attachment = false
					this.$toasted.error('Gagal mengunggah surat').goAway(3000)
					this.revisionForm.submitBtn = {
						loader: false,
						text: 'Simpan'
					}
					return false
				}
            }
            this.revisionForm.submitBtn = {
                loader: false,
                text: 'Simpan'
            }
			// Update revision
			if (this.saveState.attachment && !this.saveState.revision) {
				try {
					let revisionProp = await this.updateRevision()
					this.saveState.revision = true
				} catch (err) {
					this.saveState.revision = false
					this.$toasted.error('Gagal menyimpan data revisi').goAway(3000)
					this.revisionForm.submitBtn = {
						loader: false,
						text: 'Simpan'
					}
					return false
				}
			}
			// Finishing the successful save process
			this.$toasted.success('Sukses menyimpan data revisi').goAway(3000)
			this.closePopupModal(true)
        },
        // Validation
		validateForm (field, event) {
			if (field == 'docNo') {
				if (this.revisionForm.input.docNo != '') {
                    this.revisionForm.validate.docNo = true
				} else {
                    this.revisionForm.validate.docNo = false
                    this.revisionForm.submitBtn.disabled = true
					return false
				}
            }
			if (field == 'title') {
				if (this.revisionForm.input.title != '') {
                    this.revisionForm.validate.title = true
				} else {
                    this.revisionForm.validate.title = false
                    this.revisionForm.submitBtn.disabled = true
					return false
				}
            }
            if (field == 'attachment') {
                if (this.attachmentForm.input.source == 'Template') {
                    if (this.attachmentForm.input.selectedTemplate != null) {
                        this.revisionForm.validate.attachment = true
                    } else {
                        this.revisionForm.validate.attachment = false
                        this.revisionForm.submitBtn.disabled = true
                        return false
                    }
                } else {
                    if (
                        this.attachmentForm.input.file != null &&
                        this.attachmentForm.input.filename != ''
                    ) {
                        this.revisionForm.validate.attachment = true
                    } else {
                        this.revisionForm.validate.attachment = false
                        this.revisionForm.submitBtn.disabled = true
                        return false
                    }
                }
            }

			if (
                this.revisionForm.validate.docNo &&
                this.revisionForm.validate.title &&
                this.revisionForm.validate.attachment
            ) {
                this.revisionForm.submitBtn.disabled = false
            } else {
                this.revisionForm.submitBtn.disabled = true
            }
        },
		closePopupModal (refreshState) {
            // Reset form input values
            this.saveState = {
                attachment: false,
                revision: false
            }
            this.attachmentForm.input = Object.assign({}, this.attachmentForm.defaultValue)
            this.revisionForm.input = Object.assign({}, this.revisionForm.defaultValue)
            this.revisionForm.validate = {
                docNo: null,
                title: null,
                attachment: null
            }
            this.revisionForm.submitBtn = {
                loader: false,
                text: 'Simpan',
                disabled: true
            }
            this.$emit('close-popup-modal', refreshState)
		}
    }
}
</script>